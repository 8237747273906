import {ENV_GATSBY_DTC_UNIT, ENV_GATSBY_MATRIX_BASE_URL, ENV_GENERIC_LL_SIGNUP_LINK} from "./secrets";

export enum ROUTE_LINKS {
  index = "/",
  aboutUs = "/about",
  careers = "/careers",
  contactUs = "/contact-us",
  managerLanding = "/manager",
  renterLanding = "/renter",
  news = "/news",
  notFound = "/404",
}

export const EXTERNAL_ROUTE_LINKS = {
  DTC: `${ENV_GATSBY_MATRIX_BASE_URL}/units/${ENV_GATSBY_DTC_UNIT}/apply`,
  Login: ENV_GENERIC_LL_SIGNUP_LINK ? ENV_GENERIC_LL_SIGNUP_LINK : `${ENV_GATSBY_MATRIX_BASE_URL}/auth/login`,
};
