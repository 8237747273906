import axios from "axios";
import { ENV_GATSBY_BASE_URL } from "../utils/secrets";

const BASE_URL = ENV_GATSBY_BASE_URL;

const apiService = axios.create({ baseURL: BASE_URL });

export async function subscribeToNewsletter(data: { email: string }) {
  try {
    return apiService.post("/subscribe-newsletter", data);
  } catch (e) {
    console.error(e);
    throw e;
  }
}
